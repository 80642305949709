import React from "react";

const ContactUs = () => {
    return (
        <div className="App flex justify-center items-center mx-auto">
            <div className="glass2 p-5">
                <h1 className="headerText text-center text-3xl pb-3">Contact Us</h1>
                <div>
                    <p className="headerText text-lg">If you have any questions about these Terms and Conditions, You can contact us:</p>
                    <ul className="list-disc">
                        <li className="headerText text-lg ml-6 text-coal">By email: support@scansolutions.com</li>
                        <li className="headerText text-lg ml-6 text-coal">visiting this page on our website: www.scansolutions.com/contactus</li>
                        <li className="headerText text-lg ml-6 text-coal">By phone number: 866.834.1194</li>
                        <li className="headerText text-lg ml-6 text-coal">By mail: 9700 Farrar Ct Richmond, VA 23236, United States</li>
                    </ul>

                </div>
            </div>
        </div>
    )
}

export default ContactUs