import React, { useState, useEffect, useCallback, useRef, useContext } from "react";
import SideBar from "../components/sideBar";
import IgniteGrid from "../components/igniteGrid";
import './styles.css'
import Alert from "../components/alert";
import { getVehicleReports } from "../api/getReports";
import CommonModal from "../components/modal";
import GetUsersList from "../api/getUserList";
import GetUserDevices from "../api/getUserDevices";
import GetModal from "../api/getModal";
// import { Context } from "../layout/mainLayout";
import GetLotHistory from "../api/getOtherReports";
import GetHeaderType from "../api/getHeaderType";
import GridSettings from "../api/getGridSettings";
import BeaconOps from "../api/beaconOps";
import DataTransformer from "../utils/dataTransformer";
import { ReportTypeContext } from "../utils/reportTypeContext";
import { GridDataState, Store, clearLotLocation, clearVinNotes, setGridData, setLotLocation } from "../utils/store";
import { GetFileNames } from "../api/getInvoiceAPI";
import { GetVinNotes } from "../api/getVinNotes";
import { useSelector } from "react-redux";
import GetContextReport from "../api/getContextReports";
import { GETAccessoryList } from "../api/accessoryAPI";
import { useSearch } from "../context/searchContext";
import { searchAPI } from "../api/searchApi";

export interface SidebarToggle {
    (collapsed: boolean): void;
}

export interface SidebarMenuSelected {
    (selected: string): void;
}

export interface AlertsData {
    (errCode: number, errMsg: string, status: string): void;
}

export interface HideData {
    (data: { key: string, hidden: boolean }): void;
}

// interface ContextType {
//     reportTypeHeader: string;
//     setReportTypeHeader: (header: string) => void;
// }

const Dashboard = () => {
    const [data, setData] = useState<Record<string, any>[]>([])
    const [collapsed, setCollapsed] = useState(false);
    const [reportType1, setReportType1] = useState('All Vehicles');
    const [modal, setModal] = useState(false);
    const [title, setTitle] = useState('');
    const alreadyFetched = useRef(false);
    const [errorCode, setErrorCode] = useState<number>();
    const [errorMessage, setErrorMessage] = useState('');
    const [gridOperations, setGridOperations] = useState<string | null>();
    const [inventoryDate, setInventoryDate] = useState<string | null>();
    const [hiddenFields, setHiddenFields] = useState<string[]>([]);
    const [visibleFields, setVisibleFields] = useState<string[]>([]);
    const [hiddenData, setHiddenData] = useState<object>({});
    const [widthData, setWidthData] = useState<object>([]);
    const { reportType } = useContext(ReportTypeContext) || {};
    const lotLocationData = useSelector((state: GridDataState) => state.gridData.lotLocation);
    const vinNotes = useSelector((state: GridDataState) => state.gridData.vinNotes);
    const hasFetchedAllVehicles = useRef(false);
    let Did = sessionStorage.getItem('dealerId');
    let userName = sessionStorage.getItem('userName');
    let vins = sessionStorage.getItem('vin');
    const { searchTerm, clearSearchTerm } = useSearch();

    const fetchData = useCallback(async (report: any) => {
        setGridOperations('')
        setData([]);
        // if (alreadyFetched.current) return;
        try {
            alreadyFetched.current = true
            if (Did && userName) {
                if (report === "lotHistory") {
                    let res = await GetLotHistory(report, parseInt(Did))
                    const transformedArray = res.map((item: any) => {
                        return {
                            ...item,
                            "Print": ""
                        }
                    })
                    setData(transformedArray)
                } else if (report === "lotHistory1" && vins) {
                    let res = await GetContextReport(parseInt(Did), vins, report)
                    const transformedArray = res.map((item: any) => {
                        return {
                            ...item,
                            "Print": ""
                        }
                    })
                    setData(transformedArray)
                } else if (report === "scannedVehicleNotes") {
                    let res = await GetLotHistory(report, parseInt(Did))
                    const transformedArray = res.map((item: any) => {
                        return {
                            ...item,
                        }
                    })
                    setData(transformedArray)
                } else if (report === "Extra Vehicles") {
                    let res = await GetLotHistory(report, parseInt(Did))
                    const transformedArray = res.map((item: any, index: number) => {
                        return {
                            "id": index + 1,
                            "Add": "",
                            ...item,
                        }
                    })
                    if (transformedArray.length === 0) {
                        transformedArray.push({
                            "id": transformedArray.length + 1,
                            "Add": '',
                            "scanId": '',
                            "vin": '',
                            "scannedDate": '',
                            "stockNo": '',
                            "updateStatus": '',
                            "status": '',
                            "scannedLotLocation": '',
                            "color": '',
                            "vehicleStatus": '',
                            "lastknownlocation": ''
                        })
                    }
                    setData(transformedArray)
                } else if (report === "beaconVehiclesReport") {
                    let transformedArray: { [key: string]: any }[] = [];
                    let keyList: string[] = ["beaconIDForVehicles", "vehicleStatus", "stockNo", "vin", "modelYear", "make", "body", "type", "trimLevel", "color", "miles", "arrivalDate", "age", "retailPrice", "lotLocation", "scannedLotLocation", "lastScanned", "qrScannedCount"]
                    let resbeaconVehicleReport = await getVehicleReports('All Vehicles', parseInt(Did), false);
                    const transformedArraySub = resbeaconVehicleReport.map((item: { gpsEnable: any; scanned: any; scannedDate: any; scannedLotLocation: any; vehicleStatus: any; vehicles: any; }) => {
                        const { scannedLotLocation, vehicleStatus, vehicles } = item;
                        return {
                            scannedLotLocation,
                            vehicleStatus,
                            ...vehicles,
                        }
                    })
                    transformedArray = DataTransformer('vehicleBeacon', transformedArraySub, keyList)
                    setData(transformedArray)
                } else if (report === 'beaconKeysReport') {
                    let transformedArray: { [key: string]: any }[] = [];
                    let keyList: string[] = ["beaconID1ForKeys", "beaconID2ForKeys", "vehicleStatus", "stockNo", "vin", "modelYear", "make", "body", "type", "trimLevel", "color", "miles", "arrivalDate", "age", "retailPrice", "lotLocation", "scannedLotLocation", "lastScanned", "qrScannedCount"]
                    let resbeaconKeysReport = await getVehicleReports('All Vehicles', parseInt(Did), false);
                    const transformedArraySub = resbeaconKeysReport.map((item: { gpsEnable: any; scanned: any; scannedDate: any; scannedLotLocation: any; vehicleStatus: any; vehicles: any; }) => {
                        const { scannedLotLocation, vehicleStatus, vehicles } = item;
                        return {
                            scannedLotLocation,
                            vehicleStatus,
                            ...vehicles,
                        }
                    })
                    transformedArray = DataTransformer('keyBeacon', transformedArraySub, keyList)
                    setData(transformedArray)
                } else if (report === 'getBeaconKeys') {
                    let resBeaconKeys = await BeaconOps('get', 'keys', parseInt(Did), '');
                    let KeyList = ["id", "beaconID1ForKeys", "beaconID2ForKeys", "vin"]
                    let transformedArray = DataTransformer('beaconKeyDisplay', resBeaconKeys, KeyList)
                    setData(transformedArray)
                } else if (report === 'getBeaconVehicles') {
                    let resBeaconVehicles = await BeaconOps('get', 'vehicles', parseInt(Did), '');
                } else if (report === 'accessory') {
                    let resAccessory = await GETAccessoryList(parseInt(Did))
                    if (resAccessory.length === 0) {
                        resAccessory.push({
                            "name": "",
                            "description": "",
                            "accessoryPrice": "",
                            "type": ""
                        })
                    }
                    setData(resAccessory)
                } else {
                    let resMain = await getVehicleReports("", parseInt(Did), true);
                    let resFileNames = await GetFileNames(parseInt(Did));
                    sessionStorage.setItem("fileNames", resFileNames)
                    let arr1: any[] = []
                    let resGridSettings = await GridSettings(userName);
                    if (resGridSettings !== "Error") {
                        resGridSettings = JSON.parse(resGridSettings);
                        resGridSettings.forEach((element: { hidden: boolean; field: string; }) => {
                            //sending the values to array to the sidebar
                            arr1.push({ key: element.field, hidden: element.hidden })
                            if (element.hidden === true) {
                                setHiddenFields(prevHiddenFields => [...prevHiddenFields, element.field])
                            } else {
                                setVisibleFields(prevHiddenFields => [...prevHiddenFields, element.field])
                            }
                        });
                        Store.dispatch(setGridData(arr1));
                    }
                    localStorage.setItem("scannedLotLocations", resMain.scannedLotLocations);
                    localStorage.setItem("statuses", resMain.statuses);
                    localStorage.setItem("lotLocations", resMain.lotLocations);
                    let headerRes = await GetHeaderType('', parseInt(Did), 'getInventoryDate');
                    if (headerRes) {
                        setInventoryDate(new Date(headerRes.currentAsOf).toLocaleString());
                    }
                    let res = await getVehicleReports(report, parseInt(Did), false)
                    localStorage.setItem("dealershipData", JSON.stringify(res[0].vehicles.dealership))
                    const storedDealershipName = sessionStorage.getItem('dealershipName');
                    // If dealershipName is not present in sessionStorage, set a default value
                    if (!storedDealershipName) {
                        const defaultDealershipName = res[0].vehicles.dealership.dealerShipName;
                        sessionStorage.setItem('dealershipName', defaultDealershipName);
                    }
                    const transformedArray = res.map((item: { gpsEnable: any; scanned: any; scannedDate: any; scannedLotLocation: any; vehicleStatus: any; vehicles: any; }) => {
                        const { gpsEnable, scanned, scannedDate, scannedLotLocation, vehicleStatus, vehicles } = item;
                        return {
                            gpsEnable,
                            scanned,
                            scannedDate,
                            scannedLotLocation,
                            vehicleStatus,
                            "Print": "",
                            ...vehicles,
                        }
                    })
                    if (resGridSettings === "Error") {
                        setData(transformedArray);
                        const defaultDisplay = ["make", "model", "vehicleStatus", "stockNo", "vin", "body", "type", "trimLevel", "color", "miles", "arrivalDate", "retailPrice", "lotLocation", "scannedLotLocation", "lastScanned", "qRScannedCount"];

                        const gridSettings = transformedArray.reduce((acc: { [key: string]: boolean }, item: any) => {
                            Object.keys(item).forEach((key: string) => {
                                acc[key] = defaultDisplay.includes(key);
                            });
                            return acc;
                        }, {});

                        Store.dispatch(setGridData(Object.keys(gridSettings).map(key => ({ key, hidden: !gridSettings[key] }))));
                    } else {
                        let gridSettingsData = reOrderData(transformedArray, resGridSettings)
                        setData(gridSettingsData);

                        let headerWidthArray = resGridSettings.map((setting: { header: any; width: any; }) => ({
                            header: setting.header,
                            width: setting.width
                        }));

                        setWidthData(headerWidthArray)
                    }
                }
            }
        } catch (error: any) {
            console.error(error.message);
            setErrorCode(1)
            setErrorMessage(error.message)
        } finally {
            alreadyFetched.current = false
        }
    }, [Did, alreadyFetched, userName, vins])

    const handelGetExtraData = useCallback(async (operation: string) => {
        setGridOperations('')
        setData([])
        let result: any
        let dataDisplay: any
        try {
            alreadyFetched.current = true
            if (operation === "userList") {
                result = await GetUsersList()
                dataDisplay = result.map((user: { userName: any; email: any; roleName: any; dealershipName: any; phoneNumber: any; tempPassword: any; enable: any; id: any; }) => ({
                    'userName': user.userName,
                    'email': user.email,
                    'roleName': user.roleName,
                    'dealershipName': user.dealershipName,
                    'phoneNumber': user.phoneNumber,
                    'tempPassword': user.tempPassword,
                    'enable': user.enable,
                    'id': user.id
                }));
                setData(dataDisplay);
            } else if (operation === "vehicleStatus") {
                if (Did) {
                    result = await GetLotHistory(operation, parseInt(Did))
                    result = result.map((item: any, index: number) => ({ ...item, id: index + 1 }))
                    setData(result)
                }
            } else if (operation === "uploadDMS") {
                result = await GetModal('POST', 'VehWizAPI/UpdateScannedVehicles', '')
                if (result === "") {
                    setErrorCode(0);
                    setErrorMessage('DMS Uploaded successfully');
                } else {
                    alert(result)
                }
            } else if (operation === 'endInventory') {
                if (Did) {
                    result = await GetHeaderType('', parseInt(Did), 'endInventory')
                    if (result === "\"Success\"") {
                        setErrorCode(0);
                        setErrorMessage('Inventory Stopped');
                        window.location.reload()
                    } else {
                        setErrorCode(1);
                        setErrorMessage('Failed to stop the inventory')
                    }
                }
            }
        } catch (error: any) {
            console.error(error.message);
            setErrorCode(1)
            setErrorMessage(error.message)
        } finally {
            alreadyFetched.current = false
        }
    }, [Did, alreadyFetched]);

    const handleGetVinData = useCallback(async (vinData: string) => {
        setData([]);
        try {
            alreadyFetched.current = true
            if (Did) {
                let result = await GetVinNotes(vinData, parseInt(Did))
                const transformedArray = result.map((item: any) => {
                    return {
                        ...item,
                    }
                })
                setData(transformedArray)
                setReportType1("scannedVehicleNotes");
            }
        } catch (error: any) {
            console.error(error.message);
            setErrorCode(1)
            setErrorMessage(error.message)
        }
    }, [Did, alreadyFetched]);

    const handelGetUserDevices = async () => {
        setData([])
        alreadyFetched.current = true
        if (Did) {
            try {
                let res = await GetUserDevices(parseInt(Did))
                const mappedRes = res.map((item: any) => ({
                    ...item
                }));
                setData(mappedRes)
            } catch (err: any) {
                console.error(err.message);
                setErrorCode(1)
                setErrorMessage(err.message)
            } finally {
                alreadyFetched.current = false
            }
        }
    }

    const handleNewInventoryEvent = (event: CustomEvent) => {
        event.preventDefault()
        setModal(true)
        setTitle('Select Inventory')
    }

    const handleDMSUploadEvent = useCallback((event: CustomEvent) => {
        event.preventDefault()
        handelGetExtraData('uploadDMS');
    }, [handelGetExtraData])

    async function handleGetSearchData(report: string, term: string) {
        if(report === "All Vehicles" || report === "Missing Vehicles" || report === "Extra Vehicles" || report === "Scanned Vehicles") {
            let res = await searchAPI(report, term);
            let resGridSettings: any;
            if(userName) {
                resGridSettings = await GridSettings(userName);
            }
            if(res.length > 0) {
                setData([])
                const transformedArray = res.map((item: { gpsEnable: any; scanned: any; scannedDate: any; scannedLotLocation: any; vehicleStatus: any; vehicles: any; }) => {
                    const { gpsEnable, scanned, scannedDate, scannedLotLocation, vehicleStatus, vehicles } = item;
                    return {
                        gpsEnable,
                        scanned,
                        scannedDate,
                        scannedLotLocation,
                        vehicleStatus,
                        "Print": "",
                        ...vehicles,
                    }
                })

                if (resGridSettings === "Error") {
                    setData(transformedArray);
                    const defaultDisplay = ["make", "model", "vehicleStatus", "stockNo", "vin", "body", "type", "trimLevel", "color", "miles", "arrivalDate", "retailPrice", "lotLocation", "scannedLotLocation", "lastScanned", "qRScannedCount"];
        
                    const gridSettings = transformedArray.reduce((acc: { [key: string]: boolean }, item: any) => {
                        Object.keys(item).forEach((key: string) => {
                            acc[key] = defaultDisplay.includes(key);
                        });
                        return acc;
                    }, {});
        
                    Store.dispatch(setGridData(Object.keys(gridSettings).map(key => ({ key, hidden: !gridSettings[key] }))));
                } else {
					resGridSettings = JSON.parse(resGridSettings);
                    let gridSettingsData = reOrderData(transformedArray, resGridSettings)
                    setData(gridSettingsData);
        
                    let headerWidthArray = resGridSettings.map((setting: { header: any; width: any; }) => ({
                        header: setting.header,
                        width: setting.width
                    }));
        
                    setWidthData(headerWidthArray)
                }
            } else {
				setErrorCode(1);
                setErrorMessage("No such item exists in the grid");
			}
        } else {
			setErrorCode(1);
            setErrorMessage("Search is not available for this report");
		}
		clearSearchTerm();
    }
    

    useEffect(() => {
        let shouldFetchAllVehicles = !hasFetchedAllVehicles.current;
        if (lotLocationData) {
            setReportType1("lotHistory")
            fetchData('lotHistory1');
            Store.dispatch(clearLotLocation());
            shouldFetchAllVehicles = false;
        } else if (vinNotes) {
            handleGetVinData(vinNotes)
            shouldFetchAllVehicles = false;
        }

        if (shouldFetchAllVehicles) {
            fetchData('All Vehicles');
            hasFetchedAllVehicles.current = true;
        }

        if(searchTerm !== '') {
            handleGetSearchData(reportType1, searchTerm)
        }

        const handleInventoryRefresh = () => {
            let reportFilter: string = '';
            switch (reportType1){
                case 'Scanned Vehicles':
                    reportFilter = 'Scanned'
                    break;
                case 'Missing Vehicles':
                    reportFilter = 'Missing'
                    break;
                default:
                    reportFilter = reportType1
                    break;
 
            }
            fetchData(reportFilter);
        };

        window.addEventListener("newInventory", handleNewInventoryEvent as EventListener);
        window.addEventListener("uploadDMS", handleDMSUploadEvent as EventListener);
        window.addEventListener("inventoryRefresh", handleInventoryRefresh as EventListener);
        return () => {
            window.removeEventListener("newInventory", handleNewInventoryEvent as EventListener);
            window.removeEventListener("uploadDMS", handleDMSUploadEvent as EventListener);
            window.removeEventListener("inventoryRefresh", handleInventoryRefresh as EventListener);
        };
    }, [fetchData, lotLocationData, vinNotes, handleGetVinData, handleDMSUploadEvent, reportType1, searchTerm])

    const handleToggle: SidebarToggle = (isCollapsed) => {
        setCollapsed(isCollapsed);
    }

    const downloadFile = (driverName: any) => {
        const link = document.createElement("a");
        // if (driverName === "TSC") {
        //     link.href = "/assets/TSCDrivers.zip"
        // } else {
        //     link.href = "/assets/ZebraDrivers.zip"
        // }
        // link.download = driverName + '.zip';
        link.href = '/assets/jspm7-7.0.0.0-win.exe';
        link.download = 'JSPM7.0' + '.exe';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link)
    }

    const handleMenuSelected = async (isSelected: any) => {
        // Report Dropdowns
        if (isSelected === "All Vehicles") {
            setReportType1('All Vehicles')
            fetchData('All Vehicles')
        } else if (isSelected === "Report Missing") {
            setReportType1('Missing Vehicles')
            fetchData('Missing')
        } else if (isSelected === "Report Scanned") {
            setReportType1('Scanned Vehicles')
            fetchData('Scanned')
        } else if (isSelected === "Report-Extra Vehicles") {
            setReportType1('Extra Vehicles')
            fetchData('Extra Vehicles')
        } else if (isSelected === "Report Summary") {
            setModal(true)
            setTitle(isSelected)
            alreadyFetched.current = true
        } else if (isSelected === "Lot History") {
            setReportType1("lotHistory")
            fetchData('lotHistory')
        } else if (isSelected === "Scanned Vehicle Notes") {
            setReportType1("scannedVehicleNotes")
            fetchData("scannedVehicleNotes")
        } else if (isSelected === "User Devices") {
            handelGetUserDevices()
            setReportType1("User Devices")
            //  Settings Dropdowns
        } else if (isSelected === "DMS Connectivity") {
            setModal(true)
            setTitle(isSelected)
        } else if (isSelected === "Label Config") {
            setModal(true)
            setTitle("Label Request Information")
        } else if (isSelected === "Preferences") {
            setModal(true)
            setTitle("Preferences")
        } else if (isSelected === "Download Label Print Support") {
            downloadFile('');
        } else if (isSelected === "User List") {
            handelGetExtraData("userList")
            setReportType1("userList")
        } else if (isSelected === "Enable QR-Code") {
            setModal(true)
            setTitle("Enable QR-Code")
        } else if (isSelected === "Manage Vehicle Status") {
            handelGetExtraData("vehicleStatus");
            setReportType1("vehicleStatus");
            // File dropdowns
        } else if (isSelected === "CSV Files For FileBased") {
            window.location.href = '/dashboard/filebasedDMS';
        } else if (isSelected === "Save grid settings") {
            setReportType1("saveGrid")
        } else if (isSelected === "Clear grid settings") {
            setReportType1("clearGrid")
        } else if (isSelected === "Column Selector") {
            if (reportType1 === "All Vehicles" || reportType1 === "Missing Vehicles" || reportType1 === "Scanned Vehicles") {
                setModal(true);
                setTitle("columnChooser")
            } else {
                setErrorCode(1);
                setErrorMessage("This feature is only available for vehicle reports")
            }
            //Help Menu
        } else if (isSelected === "User Instructions") {
            window.open("https://academy.scansolutions.com/hc/en-us/categories/204419588-Vehicle-Wizard-Guides", "_blank");
            return;
        } else if (isSelected === "Request Remote Support") {
            window.open("https://scansolutions.screenconnect.com/", "_blank");
            return
            // Shop Menu
        } else if (isSelected === "Order Labels") {
            window.open("https://supplies.dealertrack.com/", "_blank");
            // Tools Menu
        } else if (isSelected === "Print Labels") {
            setGridOperations('printLabels')
        } else if (isSelected === "Print Report") {
            setReportType1("printReport");
        } else if (isSelected === "Recent Downloads") {
            setModal(true)
            setTitle("Recent Downloads")
        } else if (isSelected === "Export Data") {
            setGridOperations("Export")
        } else if (isSelected === "Vehicle Accessory") {
            setReportType1("accessory");
            fetchData("accessory")
            // Beacons Menu
        } else if (isSelected === 'Beacon - Keys') {
            setReportType1('getBeaconKeys')
            fetchData('getBeaconKeys')
        } else if (isSelected === 'Beacon - Vehicles') {
            setReportType1('getBeaconVehicles')
            fetchData('getBeaconVehicles')
        } else if (isSelected === 'Report - For Vehicles') {
            setReportType1('beaconVehiclesReport')
            fetchData('beaconVehiclesReport')
        } else if (isSelected === 'Report - For Keys') {
            setReportType1('beaconKeysReport')
            fetchData('beaconKeysReport')
            //  default loading
        } else {
            setReportType1('All Vehicles')
            fetchData('All Vehicles')
        }

        // }    else if (isSelected === "Add Scanned Vehicles") {
        //     setModal(true)
        //     setTitle("scanVehicles")
        // } else if (isSelected === "New Inventory") {
        //     if (labelUpdate === "Begin New Inventory") {
        //         setModal(true)
        //         setTitle('Select Inventory')
        //     } else if (labelUpdate === "End Inventory") {
        //         handelGetExtraData('endInventory');
        //     }
        //     // Beacon dropdowns
        // } 
    }

    const handelAlertChange = (errCode: number, errMsg: string, status: string) => {
        setErrorCode(errCode)
        setErrorMessage(errMsg)
        if (status === "Success" || status === "Info") {
            if (title === "scanVehicles") {
                window.location.reload();
            }
            setModal(!modal)
        }
    }

    const handelRowHide = (hiddenData: any) => {
        setHiddenData(hiddenData)
    }

    const reOrderData = (apiData: any[], settingsData: any) => {
        const GridSettingsData = settingsData;
        const GridSettingsKeyOrder = GridSettingsData.map((item: { field: any; }) => item.field);

        const reorderedData = apiData.map(data => {
            const newObject: Record<string, any> = {};

            GridSettingsKeyOrder.forEach((key: string) => {
                newObject[key] = data[key];
            });

            return newObject;
        });

        return reorderedData;
    }

    const clearOperationState = () => {
        setGridOperations('')
    }

    return (
        <div className="flex dashboardContainer resize-none rounded-md">
            <aside className={collapsed ? 'collapsed h-[100%]' : 'h-[100%]'}>
                <SideBar onToggle={handleToggle} onMenuSelected={handleMenuSelected} />
            </aside>
            <section className="overflow-hidden w-full">
                <div className="pt-2 pb-2">
                    <div className="flex justify-start items-start pl-3">
                        {reportType1 === 'All Vehicles' || reportType1 === 'Missing Vehicles' || reportType1 === 'Extra Vehicles' || reportType1 === 'Scanned Vehicles' ? (
                            <p className="text-pantone-grey headerText drop-shadow-md"><span className="text-lg font-semibold">Current Report: <u>{reportType1}</u></span> | <i>Inventory current as of: {inventoryDate ? inventoryDate : 'not downloaded'}</i></p>
                        ) : null}
                    </div>
                </div>
                <div className="p-3">
                    <div className="w-full rounded-md" style={{ fontFamily: 'Montserrat' }}>
                        <IgniteGrid data={data} report={reportType1} hiddenData={hiddenFields} visibleData={visibleFields} headerOps={reportType} dataHidden={hiddenData} operation={gridOperations} clearOperation={clearOperationState} colWidth={widthData} />
                    </div>
                    <div style={{ zIndex: 10 }}>
                        <CommonModal isOpen={modal} title={title} onAlert={handelAlertChange} onClose={() => setModal(false)} onHideData={handelRowHide} />
                    </div>
                </div>
            </section>
            {errorCode ? (
                <div className="absolute top-0 right-0">
                    <Alert code={errorCode} message={errorMessage} />
                </div>
            ) : null}
        </div>
    )
}

export default Dashboard