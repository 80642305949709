import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faLocationCrosshairs, faTimeline, faNoteSticky, faRecycle, faChevronRight, faFileWaveform, faFile } from "@fortawesome/free-solid-svg-icons";
import './styles.css';
import Print from "../api/print";
import { GetInvoice } from "../api/getInvoiceAPI";
import { Store, setGridData, setLotLocation, setVinNotes } from "../utils/store";
import { GETAccessoryList } from "../api/accessoryAPI";

interface MenuContextProps {
    clientX: number;
    clientY: number;
    vinData: string;
    stockData: string;
    vehId: string;
    stockNo: string;
    modelData: string;
    vinNumber: string;
    setError: (error: { code: number; message: string; success: string }) => void;
    setIsOpen: (isOpen: boolean) => void;
    setTitle: (title: string) => void;
}

const MenuContext = ({ clientX, clientY, vinData, stockData, stockNo, modelData, vinNumber, vehId, setError, setIsOpen, setTitle }: MenuContextProps) => {
    const [showFormsSubMenu, setShowFormsSubMenu] = useState<boolean>(false);
    const formsSubmenuRef = useRef<HTMLUListElement | null>(null);
    let Did = sessionStorage.getItem('dealerId');
    const files = sessionStorage.getItem('fileNames');
    const fileNames = files?.split(';').filter(fileName => fileName.trim() !== '');
    let userId: number;

    if (Did) {
        userId = parseInt(Did);
    }

    const copyValues = async (values: string) => {
        try {
            await navigator.clipboard.writeText(values);
            setError({ success: "Success", code: 0, message: 'Data has been copied to clipboard' });
        } catch (err) {
            console.log(err)
            setError({ success: "Failure", code: 1, message: 'Failed to copy the Data clipboard' });
        }
    }

    const clearRecords = async (recType: number, data: string) => {
        let res: any;
        let rec: string;
        if (recType === 0) {
            rec = 'clearQR'
        } else {
            rec = 'clearPrint'
        }
        res = await Print(rec, userId, data, '');
        if (res === "Success") {
            setError({ success: "Success", code: 0, message: 'Records have been cleared for vins: ' + data });
            window.location.reload();
        } else {
            setError({ success: "Failure", code: 1, message: 'Error occured while clearing the records of vins' });
        }
    }

    const openModal = (modalType: string) => {
        setIsOpen(true);
        setTitle(modalType);
    }

    const handleFormsHover = () => {
        setShowFormsSubMenu(true);
    }

    const handleFormsLeave = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        const target = e.relatedTarget as HTMLElement | null;
        if (formsSubmenuRef.current && !formsSubmenuRef.current.contains(target)) {
            setShowFormsSubMenu(false);
        }
    }

    const handleFormsGet = async (vehicleId: string, fileName: string, stock: string, model: string, vinNumber: string) => {
        if (!fileName.includes('Addendum')) {
            sessionStorage.setItem('vinNumber', vehicleId);
            sessionStorage.setItem('printFileName', fileName);
            let res: any
            res = await GetInvoice(vehicleId, userId, fileName);
            if (res) {
                Store.dispatch(setGridData(res));
                openModal('User Form');
            }
        } else {
            let Did = sessionStorage.getItem('dealerId');
            if (Did) {
                sessionStorage.setItem('vinNumber', vinNumber);
                sessionStorage.setItem('stockNumber', stock);
                sessionStorage.setItem('modelNumber', model);
                sessionStorage.setItem('printFileName', fileName);
                let AccessoryData = await GETAccessoryList(parseInt(Did))
                Store.dispatch(setGridData(AccessoryData));
                openModal('Addendum')
            }
        }
    }

    const alterGrid = (clicked: string) => {
        if (clicked === "LotHistory") {
            Store.dispatch(setLotLocation('lotHistory'));
        } else if (clicked === "vinNotes") {
            Store.dispatch(setVinNotes(vinData));
        }
    }

    return (
        <div className="context-menu context-menu-hover"
            style={{
                position: 'absolute',
                zIndex: 2,
                left: clientX,
                top: clientY,
                display: 'flex',
                flexDirection: 'column'
            }}>
            <ul>
                <li onClick={() => { copyValues(vinData) }}>
                    <div className="p-2 menu-item cursor-pointer flex justify-between items-center">
                        <FontAwesomeIcon icon={faCopy} />
                        <span className="pl-2">Copy Vin</span>
                        <div />
                    </div>
                </li>
                <li onClick={() => { copyValues(stockData) }}>
                    <div className="p-2 menu-item cursor-pointer flex justify-between items-center">
                        <FontAwesomeIcon icon={faCopy} />
                        <span className="pl-2">Copy StockNo</span>
                        <div />
                    </div>
                </li>
                <li onClick={() => { openModal('changeLotLocation') }}>
                    <div className="p-2 menu-item cursor-pointer flex justify-between items-center">
                        <FontAwesomeIcon icon={faLocationCrosshairs} />
                        <span className="pl-2">Change Lot Location</span>
                        <div />
                    </div>
                </li>
                <li onClick={() => { alterGrid('LotHistory') }}>
                    <div className="p-2 menu-item cursor-pointer flex justify-between items-center">
                        <FontAwesomeIcon icon={faTimeline} />
                        <span className="pl-2">Lot History</span>
                        <div />
                    </div>
                </li>
                <li onClick={() => { alterGrid('vinNotes') }}>
                    <div className="p-2 menu-item cursor-pointer flex justify-between items-center">
                        <FontAwesomeIcon icon={faNoteSticky} />
                        <span className="pl-2">Vin Notes</span>
                        <div />
                    </div>
                </li>
                <li onClick={() => { clearRecords(0, vinData) }}>
                    <div className="p-2 menu-item cursor-pointer flex justify-between items-center">
                        <FontAwesomeIcon icon={faRecycle} />
                        <span className="pl-2">Clear QR Scans</span>
                        <div />
                    </div>
                </li>
                <li onClick={() => { clearRecords(1, vinData) }}>
                    <div className="p-2 menu-item cursor-pointer flex justify-between items-center">
                        <FontAwesomeIcon icon={faRecycle} />
                        <span className="pl-2">Clear Print Records</span>
                        <div />
                    </div>
                </li>
                <li onMouseEnter={handleFormsHover} onMouseLeave={handleFormsLeave} className="formsMenu">
                    <div className="p-2 menu-item cursor-pointer flex justify-between items-center">
                        <FontAwesomeIcon icon={faFileWaveform} />
                        <span className="pl-2">Forms</span>
                        <FontAwesomeIcon icon={faChevronRight} />
                        {showFormsSubMenu && (
                            <ul className="formsSubMenu w-100" ref={formsSubmenuRef}>
                                <div className="context-menu context-menu-hover">
                                    {fileNames?.map((fileName, index) => (
                                        <li key={index} onClick={() => handleFormsGet(vehId, fileName, stockNo, modelData, vinNumber)}>
                                            <div className="p-2 menu-item cursor-pointer flex justify-start items-center w-100">
                                                <FontAwesomeIcon icon={faFile} />
                                                <span className="pl-2 truncate">{fileName}</span>
                                            </div>
                                        </li>
                                    ))}
                                </div>
                            </ul>
                        )}
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default MenuContext