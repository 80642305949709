import React, { createContext, useState, useContext } from 'react';
 
interface SearchContextType {
    searchTerm: string;
    setSearchTerm: (term: string) => void;
    clearSearchTerm: () => void;
}
 
const SearchContext = createContext<SearchContextType>({
    searchTerm: '',
    setSearchTerm: () => {},
    clearSearchTerm:() => {}
});
 
export const SearchProvider = ({ children }: any) => {
    const [searchTerm, setSearchTerm] = useState<string>('');
 
    const clearSearchTerm = () => {
        setSearchTerm('');
    }
 
    return(
        <SearchContext.Provider value={{ searchTerm, setSearchTerm, clearSearchTerm }}>
            {children}
        </SearchContext.Provider>
    )
}
 
export const useSearch = () => useContext(SearchContext);